import './App.css';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProgramsPage from './components/ProgramsPage';
import InitiativesPage from './components/IntitiativesPage';
import MentorsPage from './components/MentorsPage';
import SupportPage from './components/SupportPage';
import BlogPage from './components/BlogPage';
import ContactPage from './components/Contant';
import Footer from './components/Footer';
import MissionVisionPage from './components/MissionVisionPage';
import { useEffect } from 'react';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Donate from './components/Donate';

function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}
function App() {
  return (
    <div className="min-h-screen">
      <Navigation />
        
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/programs" element={<ProgramsPage />} />
            <Route path="/initiatives" element={<InitiativesPage />} />
            <Route path="/mentors" element={<MentorsPage />} />
            <Route path="/mission-vision" element={<MissionVisionPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="donate" element={<Donate />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
    
      <Footer />
    </div>
  );
}

export default App;
