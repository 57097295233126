import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="bg-black text-white py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid md:grid-cols-5 gap-8">
        <div>
          <h3 className="text-xl font-bold mb-4">Adroid Foundation</h3>
          <p className="text-gray-400">
            Empowering communities through quality education and skill development.
          </p>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
          <ul className="space-y-2">
            <li><Link to="/about" className="text-gray-400 hover:text-white">About Us</Link></li>
            <li><Link to="/programs" className="text-gray-400 hover:text-white">Programs</Link></li>
            <li><Link to="/initiatives" className="text-gray-400 hover:text-white">Initiatives</Link></li>
            <li><Link to="/contact" className="text-gray-400 hover:text-white">Contact</Link></li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Payment</h4>
          <ul className="space-y-2">
            <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
            <li><Link to="/terms-conditions" className="text-gray-400 hover:text-white">Terms and Conditions</Link></li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Contact</h4>
          <ul className="space-y-2 text-gray-400">
            <li>A-16, Sector-19, Rohini, New Delhi, India-110089</li>
            <li>adroidconnectz@gmail.com</li>
            <li>+91-9899265660</li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            {/* Social media icons */}
          </div>
        </div>
      </div>
      <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
        <p>&copy; 2024 Adroid Foundation. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
