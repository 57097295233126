import React, { useState } from 'react';

import Button from '../SharedComponents/Button';
import Section from '../SharedComponents/Section';
import qrcode from '../photos/qrcode.jpg';

const DonationPage = () => {
  const [donorDetails, setDonorDetails] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    pabNumber: '',
    amount: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDonorDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = "https://rzp.io/rzp/deIkjTJ0";    
  };

  return (
    <div className="min-h-screen pt-20">
      <Section>
        <h1 className="text-4xl font-bold mb-12 text-center">Make a Donation</h1>
        <div className="mx-auto max-w-4xl">
          <div className="grid md:grid-cols-2 gap-8">
            {/* Donation Form */}
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Donation Amount</label>
                  <input
                    type="number"
                    name="amount"
                    value={donorDetails.amount}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Enter donation amount"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={donorDetails.name}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={donorDetails.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Phone Number</label>
                  <input
                    type="tel"
                    name="phone"
                    value={donorDetails.phone}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Full Address</label>
                  <textarea
                    name="address"
                    value={donorDetails.address}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">PAB Number</label>
                  <input
                    type="text"
                    name="pabNumber"
                    value={donorDetails.pabNumber}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Enter PAB Number"
                    required
                  />
                </div>

                  <Button type="submit" className="w-full">
                    Pay Now
                  </Button>
              </form>
            </div>

            {/* QR Code Section */}
            <div className="bg-gray-50 p-8 rounded-lg text-center">
              <h2 className="text-2xl font-bold mb-6">Scan to Donate</h2>
              <div className="flex justify-center mb-6">
                <img
                  src={qrcode}
                  alt="UPI Donation QR Code"
                  className="max-w-full h-full object-contain"
                />
              </div>
              <p className="text-gray-600 mb-4">
                Scan this QR code with your UPI app to make a quick donation
              </p>
              <div className="bg-white p-4 rounded-lg">
                <p className="font-medium">UPI ID: cfst20003556@cub</p>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default DonationPage;