import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-[170px] sm:pt-[150px] lg:pt-32">
      <div className="flex flex-col sm:flex-row items-center gap-4 mb-8">
        <div className="w-12 h-12 bg-blue-900 rounded-full flex items-center justify-center">
          <span className="text-white text-2xl font-bold">A</span>
        </div>
        <h1 className="text-lg sm:text-xl font-bold text-blue-900 text-center sm:text-left">ADROID FOUNDATION</h1>
      </div>

      <div className="space-y-6">
        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-blue-900 mb-2 text-center sm:text-left">Privacy Policy</h2>
        </div>

        <p className="text-sm sm:text-base text-gray-700">
          This privacy policy sets out how ADROID FOUNDATION uses and protects any information that you give ADROID FOUNDATION when you use this website.
        </p>

        <p className="text-sm sm:text-base text-gray-700">
          ADROID FOUNDATION is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
        </p>

        <p className="text-sm sm:text-base text-gray-700">
          ADROID FOUNDATION may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
        </p>

        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-3">We may collect the following information:</h3>
          <ul className="list-disc pl-4 sm:pl-6 space-y-2 text-sm sm:text-base text-gray-700">
            <li>Name and job title</li>
            <li>Contact information including email address</li>
            <li>Demographic information such as postcode, preferences and interests</li>
            <li>Other information relevant to customer surveys and/or offers</li>
          </ul>
        </div>

        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-3">What we do with the information we gather</h3>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
          </p>
          <ul className="list-disc pl-4 sm:pl-6 space-y-2 text-sm sm:text-base text-gray-700">
            <li>Internal record keeping.</li>
            <li>We may use the information to improve our products and services.</li>
            <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
            <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
          </ul>
        </div>

        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-3">How we use cookies</h3>
          <p className="text-sm sm:text-base text-gray-700">
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
          </p>
        </div>

        <div>
          <h3 className="text-base sm:text-lg font-semibold mb-3">Controlling your personal information</h3>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            You may choose to restrict the collection or use of your personal information in the following ways:
          </p>
          <ul className="list-disc pl-4 sm:pl-6 space-y-2 text-sm sm:text-base text-gray-700">
            <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
            <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at adroidconnectz@gmail.com</li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-blue-900 mb-2 text-center sm:text-left">Refund Policy</h2>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            Adroid Foundation offers a refund policy to the donors only in case there has been an error/technical reason for the transfer due to which donors have mistakenly made double payments or donated extra amounts.
          </p>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            We will be happy to refund the donation provided the donor informs Adroid Foundation via email/letter and claim his/her refund within 15 calendar days from the date of donation and can expect to receive the refund within 30 calendar days from the requested date of return.
          </p>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            The information regarding the refund will be notified via email/letter with the complete details. The refund will be made by cheque in the name of the donating party only.
          </p>
          <p className="text-sm sm:text-base text-gray-700 mb-3">
            Please note that the refund will be done based on the current currency rates and all refunds will be made in INR only.
          </p>
          <div>
            <h3 className="text-base sm:text-lg font-semibold mb-3">Donation Cancellation Fee</h3>
            <p className="text-sm sm:text-base text-gray-700">
              A donation cancellation fee of 2 - 2.5% will be deducted from the donation amount for all online transactions. INR 300 will be deducted for any other mode of donation (Cheques, Demand Drafts etc.).
            </p>
          </div>
        </div>

        <div className="border-t pt-6 mt-8">
          <p className="text-xs sm:text-sm text-gray-500 italic mb-5">
            Disclaimer: The above content is created at ADROID FOUNDATION's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant's non-adherence to it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;